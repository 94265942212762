<template>
    <div style="width:100%;">
        <SiteplanRules />
        <a-modal
            title="Siteplan Base Image"
            :visible="siteplanSettings"
            :confirmLoading="loadModal"
            :footer="null"
            @cancel="onClose"
            >
            <div class="dF fC jC aC">
                <h5 class="mt-3 mb-3 text-center">Please click below to change the Base Image</h5>
                <div class="dF" style="gap:20px">
                    <div class="dF fC aC jC">
                        <span class="mb-1">Web / Mobile</span>
                        <image-box-selector  v-model="baseImage" type="images"/>
                    </div>
                    <div class="dF fC aC jC">
                        <span class="mb-1">Touchscreen / Table</span>
                        <image-box-selector  v-model="hqImage" type="images"/>
                    </div>
                </div>
            </div>
            <img :src="hqImage" width="0" height="0" class="hq-change" />
            <img :src="baseImage" width="0" height="0" class="baseImage-change" />
            <div v-if="error != ''" style="color:var(--danger)" class="text-center">{{error}}</div>
            <div class="dF jE">
                <a-button @click="onClose" class="cancel-button">CANCEL</a-button>
                <a-button class="ml-2" type="primary" @click="changeImage">OK</a-button>
            </div>
        </a-modal>
        <LotDrawer />
        <AmenityDrawer />
        <ColorSettings />
        <SettingsModal />
        <CustomFieldModal />
        <!-- <div v-show="seenIntro==false" class="dF jC fC aC" style="margin-top: 200px;">
            <img src="@/assets/siteplan-image.svg" />
            <h1 class="mt-5">Welcome to Community Site Plan Configurator</h1>
            <a-button @click="startPage" class="mt-5" size="large" type="primary">LET'S GET STARTED</a-button>
        </div> -->

        <div id="wcp-editor" class="w-full" :class="pageLoading ? 'jC aC' : ''" dat-img><a-icon type="loading" style="font-size:30px;" /></div>

    </div>
</template>

<script>
    import LotDrawer from '@/components/siteplan/LotDrawer'
    import CustomFieldModal from '@/components/siteplan/customFieldModal'
    import SettingsModal from '@/components/siteplan/SettingsModal'
    import ColorSettings from '@/components/siteplan/ColorSettings'
    import SiteplanRules from '@/components/siteplan/SiteplanRules'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import AmenityDrawer from '@/components/siteplan/AmenityDrawer'
    export default {
        components:{
            LotDrawer,ImageBoxSelector,SiteplanRules,AmenityDrawer, ColorSettings, SettingsModal, CustomFieldModal
        },
        data() {
            return {
                pageLoading:true,
                filter:'all',
                baseImage:'',
                hqImage:'',
                loadModal:false,
                error:''
            }
        },
        watch:{
            currentModel(val){
                this.filter = 'all'
            },
            siteplanSettings(val){
                if (val){
                    let siteplan =this.$store.state.siteplan.appData
                    console.log('SITEPLAN ', siteplan)
                    this.baseImage = siteplan.image
                    this.hqImage = siteplan.options && siteplan.options.media ? siteplan.options.media.hq : ''
                }
            },
            siteplan(newVal,oldVal){
                console.log('SITEPLAN UPDATED', newVal, oldVal)
                if (oldVal.image !== newVal.image){
                    $.imageMapProEditorDefaults.image.url = newVal.image
                    $.imageMapProEditorDefaults.general.width = newVal.width
                    $.imageMapProEditorDefaults.general.height = newVal.height

                    $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
                    $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
                    $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                }
            },
            shapes:{
                handler(newVal, oldVal) {
                    console.log('the val changedddd', newVal)
                    $.imageMapProEditorDefaults.spots = newVal
                    // reset back to 1:1 after drawing lot
                    if(oldVal.length > newVal.length) { // delete a lot
                        $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                    }
                }
            }

        },
        computed: {
            seenIntro() {
                return this.$store.state.siteplan.seenIntro
            },
            siteplanSettings(){
                return this.$store.state.siteplan.siteplanSettings
            },
            instance() {
                return this.$store.state.instance
            },
            siteplan(){
                return this.$store.state.siteplan.appData
            },
            currentElevations(){

            },
            shapes() {
                return this.$store.state.siteplan.appData.shapes
            }
        },
        methods:{
            onClose(){
                this.$store.commit('CLOSE_SETTINGS')
                $("[data-wcp-editor-toolbar-button-name=select]").click();
            },
            startPage() {
                this.$store.commit('INTRO_PAGE')
            },
            changeImage(){

                if (!this.baseImage) return this.$message.error('Please choose a Base Image')
                this.loadModal = true
                let width = document.querySelector('.baseImage-change').naturalWidth
                let height = document.querySelector('.baseImage-change').naturalHeight
                let sendObj = {image:this.baseImage,width,height, options:this.siteplan.options}
                if (this.hqImage){
                    if (!sendObj.options) sendObj.options = {}
                    sendObj.options.media = {
                        hq:this.hqImage,
                        width:document.querySelector('.hq-change').naturalWidth,
                        height:document.querySelector('.hq-change').naturalHeight
                    }
                    if ((sendObj.options.media.width/sendObj.options.media.height) != (sendObj.width/sendObj.height)) {
                        this.error = 'The aspect ratio of the base maps is not the same. In order for lots to align, the aspect ratio needs to be the same.'
                    } else {
                        this.error = ''
                    }
                }
                if (this.error == '') {
                    this.$api.put(`/siteplans/${this.instance.id}/${this.siteplan.id}`, sendObj).then( ({data}) => {
                        this.loadModal = false
                        this.$store.commit('SET_APPDATA_ONLY',data)
                        this.onClose()

                    }).catch( err => {
                        let error = this.$err(err)
                        this.$message.error(error)
                    })
                }

            },
           setListItems(xxx){
           }
        },
        created(){

        },
        mounted(){
            // window.hello = 'hejrklsjdflkjsdlkafjasdf'
            console.log('this rannnnnn')
            window.setListItems = this.setListItems;
            window.siteVue = this
            window.siteStore = this.$store

            $.imageMapProEditorDefaults.spots = this.siteplan.shapes || [];
            console.log('THIS DATA SPOTSSSSS', this.siteplan.shapes)
            // $.imageMapProEditorDefaults.spots = JSON.parse(data.spots);
            $.imageMapProEditorDefaults.image.url = this.siteplan.image
            $.imageMapProEditorDefaults.general.width = this.siteplan.width
            $.imageMapProEditorDefaults.general.height = this.siteplan.height

            $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
            $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
            this.pageLoading = true
            setTimeout(() => {
                $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                this.pageLoading = false
            }, 1000);

        },
        beforeDestroy(){
            this.pageLoading = true
        },
        created() {
            console.log('sdafklsadjflkasdjl')

            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);

        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">

.amenity-dialog {
    [role="tablist"] {
        display: none;
    }
}
    .lot-highlight{
        svg{
            fill: var(--orange) !important;
        }
    }
    .wcp-editor-toolbar-button.wcp-visible-tooltip:focus{
        background-color:var(--orange);
        color:#FFF;
    }
</style>
